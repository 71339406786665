import dayjs from "dayjs";
import {useShopStore } from "../modules/core/store/shop"

export default defineNuxtRouteMiddleware((to, from) => {  
    const shopStore = useShopStore()  
    let expireDate = shopStore.expireDate;
    console.log("expireDate===>", expireDate)    
    let addDayToExpireDate = shopStore.getExpiredDate; //  1 day and 7 hours

    if(!expireDate){
        return
    }

    console.log("addDayToExpireDate", addDayToExpireDate)

    let currentDate = dayjs(); 
    console.log("dayjs(addDayToExpireDate).isBefore(currentDate)==>", dayjs(addDayToExpireDate).isBefore(currentDate))
    if (dayjs(addDayToExpireDate).isBefore(currentDate)) {
        console.log("Expire date!!!")

        window.location.href = `/expired?storeId=${shopStore.storeId}`   
        // return
    } 

});
