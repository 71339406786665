import DataService from "./dataservice.base";
import InventoryTransactionDataService from "./dataservice.inventory.transaction";
import JobsDataService from "./dataservice.jobs";

import ReceiptDataService from "./dataservice.receipts";
import ReportDataService from "./dataservice.reports";
import SettingsDataService from "./dataservice.settings";
import MainDataService from "./dataservice.main";

export default {
  products: new DataService("/general/products"),
  employees: new DataService("/general/employees"),
  inventories: new DataService("/general/inventories"),
  // ----
  receipts: new ReceiptDataService("/receipts"),
  settings: new SettingsDataService("/general/settings"),
  invetoryTransaction: new InventoryTransactionDataService("/inventories-tx"),
  jobs: new JobsDataService("/jobs"),
  store: new DataService("/stores"),
  users: new DataService("/users"),
  reports: new ReportDataService("/reports"),
  main: new MainDataService("")
};
