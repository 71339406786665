//create CRUD class with axios
import axios, { AxiosResponse } from "axios";
import DataService from "./dataservice.base";


export default class MainDataService extends DataService {
  URL = "https://seal-app-xnngl.ondigitalocean.app";
  // URL = "http://localhost:8080/reports";

  constructor(path: string) {
    super(path);
  }

  async changePassword(params:any){
    return await axios.post(`${this.URL}/change-password`, params);
  }

}
